import styled from "styled-components"
import { Colors } from "../../components/theme"

export const Hero = styled.section`
  background: ${Colors.light};
  border-width: 1px;
  margin-bottom: 40px;
  padding: 35px 0;

  h1 {
    font-size: 35px;
    font-weight: 900;
  }
`
