import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/structure/layout"
import { Container } from "../components/styled/container"
import { Hero } from "../components/styled/hero"

const PageContent = styled.div`
  font-family: "Merriweather";
  font-size: 17px;
  line-height: 35px;
  margin: auto;
`

const SobreNosotros = () => {
  const intl = useIntl()
  const locale = intl.locale !== "es" ? `/${intl.locale}` : "es"

  if (locale === "es") {
    return (
      <Layout>
        <Hero>
          <Container>
            <h1>Sobre nosotros</h1>
            <p>
            </p>
          </Container>
        </Hero>
        <Container>
          <PageContent>
            <p>
            </p>
          </PageContent>
        </Container>
      </Layout>
    )
  }

  if (locale === "/en") {
    return (
      <Layout>
        <Hero>
          <Container>
            <h1>About us</h1>
            <p>
            </p>
          </Container>
        </Hero>
        <Container>
          <PageContent>
            <p>
            </p>
          </PageContent>
        </Container>
      </Layout>
    )
  }
}

export default SobreNosotros
